import MixinConfirm from '@/components/mixins/MixinConfirm';
export default {
    mixins: [ MixinConfirm ],
    methods:{
        
        executeUpdate(item){
            this.$router.push(this.pathBase+'/'+item.id)
        },
        executeDelete(item){
            this.openConfirm(this.$t('Esta seguro que desea eliminar este registro?')).then((response) => {
                if(response){
                    this.$http.delete(this.url + '/' + item.id, null, true, true).then((response) => {
                        if(response.success){
                            this.search();
                        }
                    });
                }
            });
        },
        executeCreate(){
            this.$router.push(this.pathCreate);
        },
        /*openConfirm(msg){
            return new Promise((resolve, reject) => {
                const data = { text: msg };
                if(data){
                    data['resolve'] = resolve;
                }
                const NgConfirmFactory = Vue.extend(NgConfirm);
                const vm = new NgConfirmFactory({
                    i18n: this.$i18n,
                    propsData: data
                });
                vm.$vuetify = this.$vuetify;
                vm.$store = this.$store;
                vm.$mount();
            });
        },*/
        executeGet(item){
            this.showModal(this.tmplShow, { item });
        }
    }

}