export default {
  data() {
    return {
      params: null,
      expanded: [],
      headers: [],
      total: 0,
      items: [],
      moduleActions: [],
      loading: true,
      loadingMore: false,
      itemsKey: 1,
      showFilters: false,
      dialogFilter: false,
      showTmpl: '',
      options: { page: 1, itemsPerPage: 10, itemsPerPageOptions: [10, 20, 50] },
      help: null,      
      tutorial: null,
      breadcrumbs: [],
    }
  },
  props: [],
  computed: {
    pages() {
      return Math.ceil(this.total / this.options.itemsPerPage);
    }
  },
  created() {
    let filtersModule = localStorage.getItem('filters_module');
    if (filtersModule) {
      filtersModule = JSON.parse(filtersModule);
      //console.log(filtersModule)
      if (filtersModule.url == this.$route.fullPath) {
        this.options.page = filtersModule._paginate.page;
        if (filtersModule.params) {
          this.form = filtersModule.params;
        }
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      let filtersModule = vm.$store.getters.getFilter(vm.title);
      // console.log(filtersModule)
      if (filtersModule && filtersModule.params) {
        vm.form = filtersModule.params;
      }
    })
  },
  mounted() {
    this.breadcrumbsInit();
    this.search();

  },
  methods: {
    breadcrumbsInit() {
      this.breadcrumbs.push({
        text: this.$t('home'),
        disabled: false,
        href: "/admin"
      });
    },
    onClickOutside() {
      // console.log('...')
    },
    can(actions, name) {
      if (typeof actions == 'string') {
        if (this.moduleActions.length > 0 && this.moduleActions.indexOf(actions) >= 0) {
          return true;
        }
        return false;
      } else {
        if (actions && actions.length > 0 && actions.indexOf(name) >= 0) {
          return true;
        }
        return false;
      }
    },
    changePage(e) {
      this.search(null, e);
    },
    changeItemsPerPage(e) {
      this.search(null, 1);
    },
    changeOrder(e) {
     
      if(e != undefined){
        this.search(null);
      }
    },
    close() {
    },
    filter(params) {
      this.search(params, 1);
    },
    loadMore() {
      this.options.page++;
      //console.log(this.options.page)
      this.loadingMore = true;
      const { data, paginate } = this.getParamsForQuery(null, this.options.page);
      this.querySelections(data, paginate).then(data => {
        this.items = [...this.items, ...data.items]
        this.total = data.total;
        this.loadingMore = false;
        this.itemsKey++;
      });
    },
    searchAndClose(params, page) {
      const { data, paginate, order } = this.getParamsForQuery(params, page);
      this.querySelections(data, paginate, order).then(data => {
        this.items = data.items
        this.total = data.total;
        this.moduleActions = data.module_actions;
        this.showFilters = false;
        this.itemsKey++;
      });
    },
    search(params, page) {
      const { data, paginate, order } = this.getParamsForQuery(params, page);
      this.querySelections(data, paginate, order).then(data => {
        this.items = data.items
        this.total = data.total;
        this.moduleActions = data.module_actions;
        this.itemsKey++;
      });
    },
    getParamsDefault(params) {
      return {};
    },
    getParamsForQuery(params, page) {
      let data = params;
      let filtersModule = this.$store.getters.getFilter(this.title);
      //localStorage.getItem('filters_module');
      //console.log(filtersModule)
      if (filtersModule) {
        //filtersModule = JSON.parse(filtersModule);
        if (filtersModule.url != this.$route.fullPath) {
          filtersModule = null;
        }
      }
      if (!params) {
        if (filtersModule && filtersModule.params) {
          data = filtersModule.params;
        }
      }
      let paginate = { page: this.options.page, max_rows: this.options.itemsPerPage };

      if (page) {
        paginate.page = page;
      } else {
        if (filtersModule && filtersModule._paginate) {
          paginate = filtersModule._paginate;
        }
      }
      let order = null;
      //console.log(this.options)
      if (this.options && this.options.sortBy && this.options.sortBy.length > 0) {
        let sortType = 'ASC';
        if (this.options.sortDesc[0]) {
          sortType = 'DESC';
        }
        order = [{ field: this.options.sortBy[0], direction: sortType }];
      } else {
        if (filtersModule && filtersModule._order) {
          order = filtersModule._order;
        }
      }
      return { data, paginate, order };
    },
    hidePaginator() {
     /* if(this.total && this.total > 0 && this.items && this.total == this.items.length){
        return true;
      }*/
      return false;
    },
    serializeQuery(params) {
      const value = {};
      for (const i in params) {
        if (Array.isArray(params[i])) {
          value[i] = params[i].map((item) => {
            if (item.id) {
              return { id: item.id };
            }
            return item;
          });
        } else {
          if (params[i] && params[i].id) {
            value[i] = { id: params[i].id };
          } else {
            value[i] = params[i];
          }
        }
      }
      return "params=" + JSON.stringify(value);
      //return new URLSearchParams(value).toString()
    },
    querySelections(params, paginate, order) {
      return new Promise((resolve, reject) => {

        //localStorage.setItem('filters_module',
        //  JSON.stringify({ url: this.$route.fullPath, params, '_paginate': paginate, '_order': order }));
        this.$store.dispatch('addFilter', { path: this.title, values: { url: this.$route.fullPath, params, '_paginate': paginate, '_order': order } });

        const paramsDefault = this.getParamsDefault(params);
        this.$http.get(this.url, { ...params, ...paramsDefault, '_paginate': paginate, '_order': order }, true)
          .then(res => {
            let { total, items, module_actions } = res
            total = parseInt(total);
            resolve({ items, total, module_actions });
          }).catch(err => {
            reject(err);
          }).finally(() => (this.loading = false));
      });
    }
  }
}
