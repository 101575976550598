import MixinConfirm from "@/components/mixins/MixinConfirm";
export default {
    mixins: [MixinConfirm],
    data() {
        return {
            urlSave: "api/v1/expense",
            cashRegister: null,
        }
    },
    mounted() {
        this.cashRegister = this.$store.getters.installation;
    },
    watch: {
        '$store.getters.installation'(val) {
            if (val) {
                this.cashRegister = val;
            }
        },
        cashRegister(val) {
            this.search();
        }
    },
    methods: {
        search(params, page) {
            const { data, paginate, order } = this.getParamsForQuery(params, page);
            if (this.cashRegister) {
                this.querySelections({ ...data, cash_register: this.cashRegister.id }, paginate, order).then(data => {
                    this.items = data.items
                    this.total = data.total;
                    this.moduleActions = data.module_actions;
                    this.itemsKey++;
                });
            }
        }
    }
}